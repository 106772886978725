/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out;
}

.animate-slide-down {
  animation: slide-down 0.3s ease-out;
}

/* Center content and set maximum width */
.center-content {
  @apply mx-auto;
}

.quill-container {
  border: 1px solid #e2e8f0; /* Tailwind's gray-300 color */
  border-radius: 0.375rem; /* Tailwind's rounded-md */
  padding: 0.375rem; /* Tailwind's py-1.5 */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Tailwind's shadow-sm */
  min-height: 220px;
}
.quill-container a {
  color: #2563eb; /* Tailwind's blue-600 color */
}


/* Assuming you have styles like this for the modal */
.modal {
  /* other styles */
  transition: transform 0.3s ease-out; /* Original duration */
}

.modal.animate-slide-down {
  transform: translateY(100%);
}

/* Reduce the duration */
.modal {
  transition: transform 0.1s ease-out; /* Reduced to 0.1s */
}



/* Tailwind-style overrides for ReactQuill */

/* Container for the Quill editor */
.my-quill-editor .ql-container {
  padding: 0; /* Remove default padding */
  background-color: #fff;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  height: 100%; /* Ensure it takes the height of the container */
}

/* Styling for the actual editor where text is typed */
.my-quill-editor .ql-editor {
  font-family: 'Inter', sans-serif;
  font-size: 1.1rem;
  color: #1f2937;
  line-height: 1.75;
  padding-left: 10px; /* Add 10px padding from the left edge */
  overflow-y: auto; /* Add vertical scroll if content exceeds height */
  height: 100%; /* Ensure the editor fills the container height */
  box-sizing: border-box; /* Ensure padding is included in width/height calculations */
}

/* Unordered and Ordered list styling */
.my-quill-editor .ql-editor ul, .my-quill-editor .ql-editor ol {
  padding-left: 22px; /* Ensure bullet points or indents are 22px from the left */
}

/* Bold text */
.my-quill-editor .ql-editor strong {
  font-weight: 700; /* Equivalent to Tailwind's font-bold */
}

/* Italic text */
.my-quill-editor .ql-editor em {
  font-style: italic; /* Equivalent to Tailwind's italic */
}

/* Underline text */
.my-quill-editor .ql-editor u {
  text-decoration: underline; /* Equivalent to Tailwind's underline */
}

/* Strikethrough text */
.my-quill-editor .ql-editor s {
  text-decoration: line-through; /* Equivalent to Tailwind's line-through */
}

/* Links */
.my-quill-editor .ql-editor a {
  color: #3b82f6; /* Equivalent to Tailwind's text-blue-500 */
  text-decoration: underline;
}

/* Blockquotes (Optional for extra text formatting) */
.my-quill-editor .ql-editor blockquote {
  padding-left: 10px; /* 10px from left */
  border-left: 4px solid #d1d5db; /* Equivalent to Tailwind's border-gray-300 */
  color: #6b7280; /* Equivalent to Tailwind's text-gray-500 */
  margin-bottom: 1rem; /* Equivalent to Tailwind's mb-4 */
}
.internal-ad-placeholder-holder {
  padding: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ad {
  height: 200px;
  width: 200px;
  background: pink;
  color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.ad:hover {
  font-size: 15px;
}
.internal-ad-placeholder-holder > .link{
  font-size: 10px;
  color: blue;
  text-decoration: underline;
}
.internal-ad-placeholder-holder > .link:hover {
  text-decoration: none;
}

.dropdown-open {
  position: absolute;
  width: 100%;
  background: white;
  left: 0;
  padding: 10px;
}